import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from 'gatsby';
import Seo from '../../components/SEO/seo';
import Layout from '../../components/LayoutPlainText';
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Privacy Verklaring",
  "path": "/nl/privacy-policy"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Privacy Verklaring`}</h1>
    <h4>{`Versie 1.6 | 10 Mei 2022`}</h4>
    <p>{`Jij bent vast benieuwd wat we met je gegevens doen! Goed om je af te vragen natuurlijk. We vinden het belangrijk dat je op de hoogte bent over hoe wij met je gegevens omgaan want wij respecteren je privacy. In deze Privacy Verklaring leggen we uit hoe we omgaan met je persoonlijke gegevens en waarom we dat doen. Heb je er vragen over? Je kunt ons bereiken op `}<a parentName="p" {...{
        "href": "mailto:privacy@flowyour.money"
      }}>{`privacy@flowyour.money`}</a></p>
    <p>{`Onze basisuitgangspunten zijn:`}</p>
    <ul>
      <li parentName="ul">{`We zullen te allen tijde je persoonsgegevens veilig bewaren en privé houden`}</li>
      <li parentName="ul">{`We zullen je data niet verkopen`}</li>
      <li parentName="ul">{`We geven je altijd de mogelijkheid om je voorkeuren over marketing aan te passen`}</li>
    </ul>
    <h2>{`1. Inleiding`}</h2>
    <p>{`Dit is de Privacy Verklaring van Flow Money Automation B.V. Wij doen ons best om jou te helpen grip op je financiële zaken te krijgen. In deze Privacy Verklaring beschrijven we welke persoonsgegevens we verwerken en waarom we die nodig hebben. Wij willen duidelijk en transparant zijn in welke persoonsgegevens we verwerken en waarom we die nodig hebben. We hebben ons bedrijf zo ingericht dat je privacy altijd als eerste wordt gewaarborgd volgens de principes van `}<em parentName="p">{`privacy by design`}</em>{` en `}<em parentName="p">{`privacy by default`}</em>{`. Flow is door een onafhankelijke auditor beoordeeld en is ISO27001 gecertificeerd. Dit is de standaard op het gebied van informatiebeveiliging. Op deze manier kunnen wij aantonen dat informatiebeveiliging bij Flow een belangrijk element is. Jaarlijks worden wij daarop beoordeeld door onafhankelijke auditors die ons scherp houden. Je kunt `}<a parentName="p" {...{
        "href": "/ISO-IEC_27001-ENG-10000319441-MSC-UKAS-NLD-3-20241129.pdf"
      }}>{`ons certificaat hier vinden`}</a>{`.`}</p>
    <p>{`Wij zullen, ondere andere:`}</p>
    <ul>
      <li parentName="ul">{`Het gebruik van je persoonlijke gegevens beperken tot het minimale wat er nodig is om Onze Diensten uit te kunnen voeren`}</li>
      <li parentName="ul">{`Ons continue informeren en laten adviseren over de verplichtingen op grond van privacywetgeving`}</li>
      <li parentName="ul">{`Betrokken personeel bewust maken en trainen om jouw privacy te waarborgen`}</li>
      <li parentName="ul">{`Samenwerken met de nationale toezichthouders (AFM, DNB, AP, FIOD)`}</li>
      <li parentName="ul">{`Strikte beveiligingsnormen en -procedures hanteren om ongeoorloofde toegang tot jouw gegevens door iedereen, inclusief ons personeel, te voorkomen`}</li>
      <li parentName="ul">{`Alleen samenwerken met partners die voldoen aan ons strenge informatiebeveiligingsbeleid zodat we jouw privacy kunnen waarborgen`}</li>
    </ul>
    <p>{`Wij behouden ons het recht onze Privacy Verklaring eenzijdig aan te passen. Het kan zijn dat je Onze Diensten niet kunt gebruiken totdat je de laatste versie van onze Privacy Verklaring hebt geaccepteerd. Wanneer er wijzigingen zijn aan de Privacy Policy dan stellen we je daarvan op de hoogte. De nieuwste versie is altijd te bekijken via: `}<a parentName="p" {...{
        "href": "https://flowyour.money/nl/privacy"
      }}>{`https://flowyour.money/privacy`}</a>{`.`}</p>
    <h2>{`2. Over ons`}</h2>
    <p>{`Flow is verantwoordelijk voor de verwerking van je gegevens. Dit nemen wij zeer serieus. Jij wilt natuurlijk ook weten wie wij zijn. Hier heb je alvast onze gegevens:`}</p>
    <p>{`Flow Money Automation B.V.
Riperwei 54
8406 AK Tijnje
Kamer van Koophandel: 72829796
E-mail: `}<a parentName="p" {...{
        "href": "mailto:hi@flowyour.money"
      }}>{`hi@flowyour.money`}</a></p>
    <p>{`Flow heeft een zogenaamde 'PSD2-licentie'. Dat is een vergunning van De Nederlandsche Bank waarmee we onze betalingsdiensten kunnen aanbieden. PSD2 staat voor 'Payment Services Directive 2' en is de richtlijn die ervoor zorgt dat Europese consumenten hun bank kunnen vragen om gegevens te delen met bedrijven zoals Flow, op een veilige en verantwoorde manier. Flow heeft sinds 13 juli 2020 een vergunning om jouw rekeninginformatie in te zien (Dienst 8) en om betaalinitiaties uit te voeren (Dienst 7), uiteraard alleen met jouw expliciete toestemming. Je kunt ons vinden in het DNB Register betaalinstellingen onder het nummer: `}<a parentName="p" {...{
        "href": "https://www.dnb.nl/openbaar-register/registerdetailpagina/?registerCode=WFTBI&relationNumber=R166735"
      }}>{`R166735`}</a></p>
    <h2>{`3. Cookies`}</h2>
    <p>{`We gebruiken cookies om je te onderscheiden van andere bezoekers op onze Site. Zo krijgen wij een beter beeld van onze bezoekers en kunnen wij de gebruikservaring van de Site verbeteren. Wil je meer weten over de cookies en ons doel daarmee? `}<a parentName="p" {...{
        "href": "https://flowyour.money/nl/cookies"
      }}>{`Bekijk dan ons cookie beleid`}</a>{`.`}</p>
    <h2>{`4. Type gegevens die we verzamelen`}</h2>
    <p>{`Bij het uitvoeren van Onze Diensten verzamelen en verwerken we gegevens over jou. Er zijn verschillende redenen waarom we deze data verwerken. Hieronder een toelichting over wat voor, hoe lang en waarom we jouw gegevens bewaren:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ID gegevens`}</strong>{`:`}<br />{`Personalia (naam, geboortedatum, geboorteplaats, geslacht), ID-document, adres, bedrijfsgegevens (KVK-nummer, UBO-informatie)`}</li>
      <li parentName="ul"><strong parentName="li">{`Communicatiegegevens`}</strong>{`:`}<br />{`Social media profiel, e-mailadres, mobiele telefoonnummer`}</li>
      <li parentName="ul"><strong parentName="li">{`Identificatiegegevens`}</strong>{`:`}<br />{`E-mailadres, mobiele telefoonnummer`}</li>
      <li parentName="ul"><strong parentName="li">{`Bankinformatie`}</strong>{`:`}<br />{`Gekoppelde banken, jouw naam bekend bij de bank, IBAN-nummer(s), saldi`}</li>
      <li parentName="ul"><strong parentName="li">{`Transactie informatie`}</strong>{`:`}<br />{`Per transactie (datum en tijd, transactiebedrag, beschrijving(en), IBAN-nummer(s), transactie bijlagen, tegenpartij(en)) en algemeen (saldi)`}</li>
      <li parentName="ul"><strong parentName="li">{`Technische informatie`}</strong>{`:`}<br />{`Een uniek identificatienummer, IP-adres, time zone setting, besturingssysteem en platform, apparaat informatie, sessie informatie, URL's die je bekijkt op onze Site, errors, duur van je bezoek, pagina interactie informatie (scroll-gedrag, muisklikken)`}</li>
      <li parentName="ul"><strong parentName="li">{`Anti Money Laundering (AML)`}</strong>{`:`}<br />{`Achtergrond checks, analyse van transacties en betaalinitiaties`}</li>
    </ul>
    <h4>{`Overzicht met gegevens`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Wat?`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Doel?`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Grondslag?`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Wanneer?`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`ID gegevens`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Identificatie`}<br />{`- Onze dienstverlening`}<br />{`- Analyse gebruik van de App`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Wettelijke verplichting`}<br />{`- Toestemming`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Tijdens het verbinden van de App met jouw bank`}<br />{`- Tijdens het gebruik van de App`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Communicatiegegevens`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Onze dienstverlening`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Gerechtvaardigd belang`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Door te communiceren met Flow`}<br />{`- Tijdens de registratie`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Identificatiegegevens`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Identificatie`}<br />{`- Onze dienstverlening`}<br />{`- Communicatie`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Gerechtvaardigd belang`}<br />{`- Uitvoering van de overeenkomst`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Tijdens de registratie`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Bankinformatie`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Onze dienstverlening`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Toestemming`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Tijdens het verbinden van de App met jouw bank`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Transactie informatie`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Onze dienstverlening`}<br />{`- Tegen witwassen en financiering terrorisme`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Toestemming`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Tijdens het verbinden van de App met jouw bank`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Technische informatie`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Onze dienstverlening`}<br />{`- Analyse gebruik van de App`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Uitvoering van de overeenkomst`}<br />{`- Toestemming`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Tijdens het gebruik van de Site en App`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Anti Money Laundering (AML)`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Tegen witwassen en financiering terrorisme`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Wettelijke verplichting`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- Na het verifiëren van je ID`}<br />{`- Bij iedere binnenkomende transactie`}<br />{` - Bij iedere betaalinitiatie`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`5. Opslaan van gegevens`}</h2>
    <p>{`De gegevens die wij verzamelen (`}<em parentName="p">{`ID gegevens, communicatiegegevens, identificatiegegevens, bankinformatie, transactie informatie, technische informatie, anti money laundering`}</em>{`) worden verstuurd naar, en bewaard bij, een locatie in de Europese Economische Ruimte (“EER”): Amazon Web Services in Frankfurt, Duitsland. We werken uitsluitend met partners die aan de beveiligingseisen voldoen en aansluiten op onze Privacy Verklaring.`}</p>
    <ul>
      <li parentName="ul">{`De gegevens die wij verzamelen bewaren wij op veilige servers. Het versturen van versleutelde gegevens gebeurd middels Transport Layer Security technologie (“TLS”).`}</li>
      <li parentName="ul">{`Wij hebben geschikte (technische en organisatorische) maatregelen genomen om je gegevens te beschermen. Wij beschermen onze apparatuur met wachtwoorden, beveiligingsprocedures zoals twee-staps verificatie en encryptie van het opslagmedium.`}</li>
      <li parentName="ul">{`Een groot deel van het opslaan van de gegevens is wettelijk verplicht volgens de Wwft (Wet ter voorkoming van witwassen en financieren van terrorisme). Daardoor moeten wij alle transacties controleren en wanneer ze ongebruikelijk zijn melden bij de toezichthouder.`}</li>
    </ul>
    <p>{`Een deel van de gegevens (communicatiegegevens) wordt opgeslagen bij onze leveranciers (waaronder Google, Facebook, Twitter en Gist) omdat we gebruik maken van hun diensten. Voor zover mogelijk zullen wij eisen dat dit opgeslagen wordt binnen de Europese Economische Ruimte (“EER”).`}</p>
    <p>{`Zoals je kunt lezen zullen we ons uiterste best doen om je persoonlijke gegevens te beveiligen. We hebben interne procedures en beleid omtrent de veiligheid van ons product om ongewenste toegang te voorkomen.`}</p>
    <h4>{`Wanneer verwijderen we jouw gegevens?`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Hoe lang bewaren we het?`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Waarom?`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`ID gegevens`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5 jaar`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wettelijke verplichting`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Communicatiegegevens`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1 jaar`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AVG`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Identificatiegegevens`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1 jaar`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AVG`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Bankinformatie`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5 jaar`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wettelijke verplichting`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Transactie informatie`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5 jaar`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wettelijke verplichting`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Technische informatie`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1 jaar`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AVG`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Anti Money Laundering (AML)`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5 jaar`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wettelijke verplichting`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Protocol Datalekken`}</h4>
    <p>{`We hebben strenge maatregelen genomen om je persoonlijke gegevens te beschermen. Daarnaast is bij Flow het `}<a parentName="p" {...{
        "href": "https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/beveiliging/acties-bij-datalekken"
      }}>{`Protocol Datalekken van het AP`}</a>{` actief. Aan de hand van dit protocol wordt bepaald of er sprake is van een datalek en onder welke voorwaarden dat gemeld moet worden bij de Autoriteit Persoonsgegevens (AP). We communiceren hier graag transparant en eerlijk met je over.`}</p>
    <h2>{`6. Uitwisselen van gegevens`}</h2>
    <p>{`We wisselen persoonlijke gegevens uit met de volgende instanties om Flow diensten te kunnen aanbieden:`}</p>
    <h4>{`Autoriteiten`}</h4>
    <p>{`Wij zijn wettelijk verplicht om ongebruikelijke transacties te melden bij de autoriteiten (Financial Intelligence Unit Nederland). Het kan voorkomen dat we je beperkingen opleggen of uitsluiten van het gebruik van Flow op basis van ongebruikelijke transacties.`}</p>
    <h4>{`Banken en financiële dienstverleners`}</h4>
    <p>{`Wij werken samen met deze partijen om Onze Diensten te kunnen aanbieden. Onze rol is om jouw persoonsgegevens te beschermen en wij zullen nooit zonder legitieme reden gegevens uitwisselen. Je gegevens zullen alleen verzonden worden naar deze dienstverleners wanneer je ons hebt gevraagd hun dienst te gebruiken.`}</p>
    <h4>{`Ten behoeve van boekhouder`}</h4>
    <p>{`In sommige gevallen kunnen wij ook gegevens delen met jouw accountant. Als jouw accountant gebruik maakt van SnelStart, dan kan jouw accountant via onze samenwerkingspartner SnelStart om jouw toestemming vragen om gegevens vanuit jouw Flow account met hem of haar te delen.`}</p>
    <p>{`Door jouw gegevens in Flow te koppelen aan de SnelStart omgeving van jouw accountant, kan jouw accountant zijn dienstverlening en jouw Flows optimaliseren. Ook kan jouw accountant door toegang te krijgen tot jouw gegevens in Flow zijn eigen flows met jou delen, zodat jij daar gebruik van kan maken.`}</p>
    <p>{`We delen alleen gegevens met jouw accountant als jij daarvoor toestemming geeft. De toestemming die jij daarvoor aan jouw accountant via onze app geeft, kan je altijd gemakkelijk via de Flow app weer intrekken, zodat jouw accountant niet langer de gegevens vanuit jouw Flow account kan inzien.`}</p>
    <p>{`Door jouw Flow gegevens met jouw accountant te delen, krijgt jouw accountant in zijn of haar SnelStart omgeving inzicht in jouw persoonlijke Flows, de naam van jouw rekening(en), IBAN nummers, gegevens van de tegenrekening, bedragen en transactieomschrijvingen.`}</p>
    <p>{`Voor meer informatie over de samenwerking tussen Flow en SnelStart `}<a parentName="p" {...{
        "href": "/nl/flow-snelstart-partnership"
      }}>{`bekijk deze pagina`}</a>{`.`}</p>
    <h4>{`Advertentie en analyse dienstverleners`}</h4>
    <p>{`We gebruiken een derde partij om analyses te doen over het gebruik van de Site (Google Analytics) en de App (Mixpanel). We wisselen geen persoonlijke gegevens uit maar stellen een profiel op van je gebruik zodat we verschillende soorten gebruikersgroepen kunnen definiëren. Wij anonimiseren je gegevens om je privacy te beschermen.`}</p>
    <h2>{`7. Communicatie`}</h2>
    <p>{`We vinden het belangrijk om uit te leggen hoe we je gegevens gebruiken om met je te communiceren en te vermelden dat jij het recht hebt om je hiervoor uit te schrijven. Je kunt je op ieder moment uitschrijven van onze mailings door onderaan de e-mail op “uitschrijven” te drukken of door contact op te nemen met `}<a parentName="p" {...{
        "href": "mailto:privacy@flowyour.money"
      }}>{`privacy@flowyour.money`}</a>{`.`}</p>
    <h2>{`8. Je rechten`}</h2>
    <p>{`Je hebt natuurlijk rechten omtrent de persoonlijke gegevens die wij verzamelen. Je kunt contact opnemen met Flow via `}<a parentName="p" {...{
        "href": "mailto:privacy@flowyour.money"
      }}>{`privacy@flowyour.money`}</a>{` om je verzoek te doen.`}</p>
    <h4>{`Het recht op inzage`}</h4>
    <p>{`Je hebt het recht inzage te vragen in de persoonsgegevens die wij verwerken. Wij zullen een kopie verstrekken van de persoonsgegevens die worden verwerkt.`}</p>
    <h4>{`Het recht op rectificatie`}</h4>
    <p>{`Wanneer jouw gegevens niet kloppen kun je ons verzoeken de gegevens aan te passen. Wij zullen dit dan corrigeren.`}</p>
    <h4>{`Het recht op gegevenswissing (vergetelheid)`}</h4>
    <p>{`Door gebruik te maken van de Diensten van Flow verzamelen we gegevens. Je hebt het recht om aan ons te vragen deze gegevens van onze systemen te verwijderen. Wij zullen aan deze eis voldoen zonder onredelijke vertraging, en wanneer het redelijkerwijs binnen onze macht is.`}</p>
    <p>{`Wanneer je je account niet meer gebruikt kun je contact opnemen via `}<a parentName="p" {...{
        "href": "mailto:privacy@flowyour.money"
      }}>{`privacy@flowyour.money`}</a>{` om te verzoeken je gegevens van onze system te verwijderen.`}</p>
    <h4>{`Het recht op overdraagbaarheid (dataportabiliteit)`}</h4>
    <p>{`Je kunt ons verzoeken om je persoonsgegevens over te dragen in een gestructureerde, gangbare en machineleesbare vorm. Op die manier kun je je gegevens zonder obstakels overdragen aan een nieuwe dienstverlener.`}</p>
    <h4>{`Het recht van bezwaar of beperking van de verwerking`}</h4>
    <p>{`In sommige gevallen kan je bezwaar maken tegen het gebruik van jouw gegevens of heb je het recht op de beperking van de verwerking.`}</p>
    <p>{`Je hebt in twee situaties het recht om aan ons te vragen jouw persoonsgegevens niet meer te gebruiken. Dit heet het recht van bezwaar. Ten eerste wanneer wij jouw gegevens gebruiken voor direct marketing. Ten tweede kan je bezwaar maken tegen het gebruik van jouw persoonsgegevens vanwege jouw specifieke situatie.`}</p>
    <p>{`Het recht van beperking van de verwerking geldt bijvoorbeeld wanneer jouw gegevens onjuist zijn, de verwerking onrechtmatig is of wanneer het verwerken van persoonsgegevens niet meer nodig is.`}</p>
    <h4>{`Afhandeling verzoeken`}</h4>
    <p>{`We zullen proberen de legitieme verzoeken binnen een maand te voldoen. In uitzonderlijke gevallen kan het langer duren dan een maand, wij vragen hiervoor je begrip.`}</p>
    <h4>{`Als we jouw toestemming nodig hebben`}</h4>
    <p>{`Als wij jouw gegevens verwerken op basis van toestemming, dan heb je altijd het recht om jouw toestemming in te trekken. Dit kan je eenvoudig doen door een e-mail te sturen naar `}<a parentName="p" {...{
        "href": "mailto:privacy@flowyour.money"
      }}>{`privacy@flowyour.money`}</a>{`.`}</p>
    <p>{`Als we geen andere grondslag hebben voor de gegevens die we op basis van toestemming verwerken, zullen we deze gegevens niet meer gebruiken en verwijderen.`}</p>
    <h4>{`Klachten`}</h4>
    <p>{`Bij klachten over bijvoorbeeld de manier waarop wij jouw gegevens gebruiken of hoe wij reageren op jouw privacy gerelateerde vragen, kan je een klacht indienen bij de Autoriteit Persoonsgegevens.`}</p>



    <Seo title={props.pageContext.frontmatter.title} path={props.pageContext.frontmatter.path} mdxType="Seo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      